<template>
  <div class="home-container">
    <div class="loader" v-if="isLoading"></div>
    <div class="container">
      <div class="card">
        <v-row no-gutters>
          <v-col cols="12" md="12" sm="12" class="text-left">
            <h1>Verify your email</h1>
            <v-alert
              color="green lighten-2"
              type="success"
              dark
              class="mt-3"
              v-if="text"
            >
              {{ text }}
            </v-alert>
            <v-alert
              v-else
              color="red lighten-2"
              type="error"
              dark
              class="mt-3"
            >
              {{ error }}
            </v-alert>
          </v-col>
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="text-left bottomSection_left d-none d-sm-block mt-4"
          >
            <p class="mt-11 mt-sm-1">
              <strong>
                <a href="javascript:void(0);" @click="loginPage()">Login</a>
                <br />
                <a :href="website">HCMS.ai</a>
              </strong>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { ACTION_VERIFY_EMAIL, GETTER_LOADING } from "../store/modules/auth";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      website: process.env.VUE_APP_WEBSITE,
      isLoading: false,
      text: null,
      error: null
    };
  },
  computed: {
    ...mapGetters("auth", {
      getter_loading: GETTER_LOADING
    })
  },
  watch: {
    getter_loading(val) {
      this.isLoading = val;
    }
  },
  async mounted() {
    if (this.$route.params.token) {
      const res = await this.verify_email(this.$route.params.token);
      if (res) {
        this.text = res.message;
      } else {
        this.error = "Token is not valid.";
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions("auth", {
      verify_email: ACTION_VERIFY_EMAIL
    }),
    loginPage() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: block;
  min-width: 100vw;
  min-height: 100vh;
  margin-top: -68px !important;
  .card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-right: -50%;
    width: 900px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 25px;
    padding-top: 65px;
    padding-bottom: 40px;
  }
}
/* ------------For mobile devices------------- */
@media (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    margin-top: 0 !important;

    .card {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      padding-top: 25px;
      text-align: center;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    .card {
      > div:nth-child(1) {
        align-items: center;
        flex-direction: row;
      }
    }
  }
}
</style>
